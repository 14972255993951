<template>
  <div class="mt-5">
    <div class="d-flex justify-content-between align-items-center">
      <p class="lead">Linha PEAD</p>
      <a class="btn btn-info"
         target="_blank"
         href="https://drive.google.com/file/d/1m3YJha8_ShF0G68Pj8fJoKnTynUMxxxz/view?usp=sharing">Nosso Catálogo</a>
    </div>


    <div class="row row-cols-1 row-cols-sm-3 row-cols-md-3 mt-4">
      <div v-for="produto in produtosFiltrados" :key="produto.id" class="col">
        <div class="card mb-5 border-0">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <router-link
                  :to="`/produtos/detalhes/${produto.url}`"
                  class="d-flex flex-column align-items-center justify-content-center"
                  style="text-decoration: none">
                <h5 class="card-title text-decoration-none text-dark" style="overflow: hidden; white-space: nowrap;">{{ produto.nome }}</h5>
                <img
                    :src="produto.fotos.capa"
                    class="card-img zoom"
                    v-bind:alt="produto.nome"
                    style="max-width: 350px"
                />
              </router-link>
              <div class="d-flex w-100">
                  <router-link
                      :to="`/produtos/detalhes/${produto.url}`"
                      class="text-decoration-none text-dark"
                  >
                    <button
                        type="button"
                        class="btn btn-secondary mt-2 mr-1"
                        style="background-color: #b62e2c"
                    >
                      + Detalhes
                    </button>
                  </router-link>
                  <Orcamento
                      :identificador="produto.url"
                      :titulo="produto.nome"
                      class="mt-2 flex-grow-1"
                  ></Orcamento>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Orcamento from "@/components/Orcamento.vue";
export default {
  data() {
    return {
      produtos: [
        {
          id: 1,
          repetido: false,
          segmento: "agro_catraca_1000ml",
          descricao_segmento: "Frasco Plástico 1000ml Cilíndrico Catraca",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico Catraca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/agro_catraca_1000ml.png'),
            detalhe: "agro_catraca_1000ml.png",
            galeria: [
              require('@/assets/images/products/agro_catraca_1000ml.png'),
              require('@/assets/images/products/cover/agro_catraca_1000ml.png')
            ]
          },
          url: "agro_catraca_1000ml",
          detalhes: {
            caracteristica: ``,
            aplicacao:
                "",
            tecnico: "Gramatura de linha : 45g  2   (demais gramaturas sob consulta)\n" +
                "Cores de linha: Natural e branco (demais cores sob consulta)\n" +
                "Material: PEAD (Polietileno de alta densidade) novo ou reciclável \n" +
                "Tampa: catraca; Material Polipropileno",
          },
        },
        {
          id: 2,
          repetido: false,
          segmento: "agro_tampa_dosadora_1000ml",
          descricao_segmento: "Agro Tampa Dosadora 1000ml",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Agro Tampa Dosadora 1000ml",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/agro_tampa_dosadora_1000ml.png'),
            detalhe: "agro_tampa_dosadora_1000ml.png",
            galeria: [
              require('@/assets/images/products/agro_tampa_dosadora_1000ml.png'),
              require('@/assets/images/products/cover/agro_tampa_dosadora_1000ml.png')
            ]
          },
          url: "agro_tampa_dosadora_1000ml",
          detalhes: {
            caracteristica: ``,
            aplicacao: "",
            tecnico: "",
          },
        },
        {
          id: 3,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_500.png'),
            detalhe: "frasco_cili_500.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_500.png'),
              require('@/assets/images/products/cover/frasco_cili_500.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "| Peso: 26 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 155mm x 75mm",
          },
        },
        {
          id: 4,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Muito usado para envase de aditivos de radiador, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 195mm x 94mm",
          },
        },
        {
          id: 5,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque-farmaceutico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "| Peso: 26 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 155mm x 75mm",
          },
        },
        {
          id: 6,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico-farmaceutico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Muito usado para envase de aditivos de radiador, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "",
          },
        },
        {
          id: 7,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Cilíndrico Batoque",
          descricao:
              "Frasco plástico usado para aditivos de motor, óleo/silicone para lubrificação, envase de produtos farmacêuticos ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_500.png'),
            detalhe: "frasco_cili_500.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_500.png'),
              require('@/assets/images/products/cover/frasco_cili_500.png')
            ]
          },
          url: "frasco-plastico-500ml-cilindrico-batoque-quimico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "| Peso: 26 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 155mm x 75mm",
          },
        },
        {
          id: 8,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Cilíndrico",
          descricao:
              "Frasco plástico para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, produtos químicos e de limpeza em geral ...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_cili_1000.png'),
            detalhe: "frasco_cili_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_cili_1000.png'),
              require('@/assets/images/products/cover/frasco_cili_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-cilindrico-quimico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD produzida pela tecnologia sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar e evitar vazamentos, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Muito usado para envase de aditivos de radiador, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "",
          },
        },
        {
          id: 9,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_1000.png'),
            detalhe: "frasco_inovah_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_1000.png'),
              require('@/assets/images/products/cover/frasco_inovah_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-inovah",
          detalhes: {
            caracteristica:
                "Frasco Inovah é uma embalagem de PEAD produzida pela tecnologia de sopro, qualidade incomparável. Possui tampa com batoque e lacre, podendo-se também aplicar selo de indução, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Embalagem Muito procurado para aditivo de radiadores e óleo/silicone para lubrificação.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 220mm x 106mm x 65mm",
          },
        },
        {
          id: 10,
          repetido: true,
          segmento: "quimico",
          descricao_segmento: "Química",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_1000.png'),
            detalhe: "frasco_inovah_1000.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_1000.png'),
              require('@/assets/images/products/cover/frasco_inovah_1000.png')
            ]
          },
          url: "frasco-plastico-1000ml-inovah-quimico",
          detalhes: {
            caracteristica:
                "Frasco Inovah é uma embalagem de PEAD produzida pela tecnologia de sopro, qualidade incomparável. Possui tampa com batoque e lacre, podendo-se também aplicar selo de indução, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Embalagem Muito procurado para aditivo de radiadores e óleo/silicone para lubrificação.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | ",
          },
        },
        {
          id: 11,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Frasco Plástico 500ml Inovah",
          descricao:
              "Frasco plástico para envase de aditivo de radiadores automotivos e óleo/silicone para lubrificação...",
          fotos: {
            capa: require('@/assets/images/products/cover/frasco_inovah_500.png'),
            detalhe: "frasco_inovah_500.png",
            galeria: [
              require('@/assets/images/products/frasco_inovah_500.png'),
              require('@/assets/images/products/cover/frasco_inovah_500.png')
            ]
          },
          url: "frasco-plastico-500ml-inovah",
          detalhes: {
            caracteristica:
                "Frasco Inovah é uma embalagem de PEAD produzida pela tecnologia de sopro, qualidade incomparável. Possui tampa com batoque e lacre, podendo-se também aplicar selo de indução, garantindo assim a segurança do seu produto.",
            aplicacao:
                "Embalagem Muito procurado para aditivo de radiadores e óleo/silicone para lubrificação.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 220mm x 106mm x 65mm",
          },
        },
        {
          id: 12,
          repetido: false,
          segmento: "atlas_1000ml",
          descricao_segmento: "Frasco Plástico 1000ml Modelo Atlas",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Frasco Plástico 1000ml Modelo Atlas",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/atlas_1000ml.png'),
            detalhe: "atlas_1000ml.png",
            galeria: [
              require('@/assets/images/products/atlas_1000ml.png'),
              require('@/assets/images/products/cover/atlas_1000ml.png')
            ]
          },
          url: "atlas_1000ml",
          detalhes: {
            caracteristica: ``,
            aplicacao: "",
            tecnico: "",
          },
        },
        {
          id: 13,
          repetido: false,
          segmento: "galao_5000ml",
          descricao_segmento: "Galão 5000ml",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000 ML - Modelo Atlas",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/atlas_5000ml.png'),
            detalhe: "atlas_5000ml.png",
            galeria: [
              require('@/assets/images/products/atlas_5000ml.png'),
              require('@/assets/images/products/cover/atlas_5000ml.png')
            ]
          },
          url: "galao_5000ml_modelo_atlas",
          detalhes: {
            caracteristica: `Galão de 5 Litros - Bombona de 5 Litros. Com visor lateral ou Sem visor lateral Materia Prima: PEAD (Polietileno de alta densidade ou reciclável)`,
            aplicacao:
                "Industria quimica. Lubrificantes, Cola, Liquido para vedação, Cloro, Desinfetante, Candida, Amaciante, Detergente, limpa pedra, entre outros. Industria cosmetica. Shampoo, Condicionador, sabonete liquido",
            tecnico: "| Peso: 26 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Batoque c/ lacre  | Cores: Natural, branco, cor | Dimensões: 155mm x 75mm",
          },
        },
        {
          id: 14,
          repetido: false,
          segmento: "galao_5000ml",
          descricao_segmento: "Galão 5000ml",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000ml - Modelo Combate",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/combate_5000ml.png'),
            detalhe: "combate_5000ml.png",
            galeria: [
              require('@/assets/images/products/combate_5000ml.png'),
              require('@/assets/images/products/cover/combate_5000ml.png')
            ]
          },
          url: "galao_combate_5000ml",
          detalhes: {
            caracteristica: `Galão de 5 Litros - Bombona de 5 Litros. Com visor lateral ou Sem visor lateral Materia Prima: PEAD (Polietileno de alta densidade ou reciclável)`,
            aplicacao:
                "Industria quimica. Lubrificantes, Cola, Liquido para vedação, Cloro, Desinfetante, Candida, Amaciante, Detergente, limpa pedra, entre outros. Industria cosmetica. Shampoo, Condicionador, sabonete liquido",
            tecnico: "Peso: 170 gramas (personalizavel)|  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Catraca c/ lacre 42mm | Cores: Natural, branco, cor | Dimensões: 35,5CM X 20CM X 11CM ",
          },
        },
        {
          id: 15,
          repetido: false,
          segmento: "standard_5000ml",
          descricao_segmento: "Galão 5.000ml - Modelo standard",
          tamanho: "5000",
          unidade_medida: "ml",
          nome: "Galão 5.000ml - Modelo standard",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/standard_5000ml.png'),
            detalhe: "standard_5000ml.png",
            galeria: [
              require('@/assets/images/products/standard_5000ml.png'),
              require('@/assets/images/products/cover/standard_5000ml.png')
            ]
          },
          url: "standard_5000ml",
          detalhes: {
            caracteristica: ``,
            aplicacao: "",
            tecnico: "",
          },
        },
        {
          id: 17,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito procurado no envase de cremes, e graxas e para envase de produtos de limpeza em geral. Utilizações: Cosmética, artesanal, escolar.",
            tecnico: "",
          },
        },
        {
          id: 18,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø | Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Reta (baixa) de rosca  | Cores: Natural, branco, cor | Dimensões: 135mm x 105mm",
          },
        },
        {
          id: 19,
          repetido: false,
          segmento: "automobilistico",
          descricao_segmento: "Automobilísta",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico: "| Peso: 45 gramas (personalizavel) |  Material: PEAD (Polietileno de alta densidade) Novo ou Reciclavel | Tampa: Tartaruga (alta) de rosca  | Cores: Natural, branco, cor | Dimensões: 142mm x 108mm",
          },
        },
        {
          id: 20,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca-cosmetico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito procurado no envase de cremes, e graxas e para envase de produtos de limpeza em geral. Utilizações: Cosmética, artesanal, escolar.",
            tecnico: "",
          },
        },
        {
          id: 21,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b-cosmetico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø",
          },
        },
        {
          id: 22,
          repetido: true,
          segmento: "cosmetico",
          descricao_segmento: "Cosmético",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a-cosmetico",
          detalhes: {
            caracteristica:
                "Cili é uma embalagem de PEAD,produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com lacre, é ideal para conservar, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito usado para envase de aditivos de motor, produtos farmacêuticos, óleo/silicone para lubrificação, cola branca, e produtos químicos e de limpeza em geral.",
            tecnico: "",
          },
        },
        {
          id: 24,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "500",
          unidade_medida: "ml",
          nome: "Pote Plástico 500ml Inovah Bio Rosca",
          descricao: "",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_500.png'),
            detalhe: "bio_b_500.png",
            galeria: [
              require('@/assets/images/products/bio_b_500.png'),
              require('@/assets/images/products/cover/bio_b_500.png')
            ]
          },
          url: "pote-plastico-500ml-inovah-bio-rosca-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa com rosca faz com que você possa abrir e fechar diversas vezes, garantindo assim a segurança de seu produtos.",
            aplicacao:
                "Muito procurado no envase de cremes, e graxas e para envase de produtos de limpeza em geral. Utilizações: Cosmética, artesanal, escolar.",
            tecnico: "",
          },
        },
        {
          id: 25,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio B",
          descricao:
              "Pote plástico para envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_b_1000.png'),
            detalhe: "bio_b_1000.png",
            galeria: [
              require('@/assets/images/products/bio_b_1000.png'),
              require('@/assets/images/products/cover/bio_b_1000.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-b-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Muito procurado no envase de cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico:
                "Utilizações: Cosmética, artesanal e escolar | Peso: 47 gramas | Capacidade: 1 quilo | Material: PEAD novo | Tampa: rosca, cor preta | Cores: Natural, branco, cor | Dimensões: 135 mm x 105 mm | Impressões: 100 mm Ø",
          },
        },
        {
          id: 26,
          repetido: true,
          segmento: "farmaceutico",
          descricao_segmento: "Farmacêutico",
          tamanho: "1000",
          unidade_medida: "ml",
          nome: "Pote Plástico 1000ml Inovah Bio A",
          descricao:
              "Pote plástico para envase de produtos farmacêuticos, cremes, cosméticos e para envase de produtos de limpeza em geral...",
          fotos: {
            capa: require('@/assets/images/products/cover/bio_a.png'),
            detalhe: "bio_a.png",
            galeria: [
              require('@/assets/images/products/bio_a.png'),
              require('@/assets/images/products/cover/bio_a.png')
            ]
          },
          url: "pote-plastico-1000ml-inovah-bio-a-farmaceutico",
          detalhes: {
            caracteristica:
                "Bio é um pote de PEAD produzida pela tecnologia a sopro, qualidade incomparável, sua tampa de rosca é indicado para envase de produto o qual necessita ser aberto e fechado inúmeras vezes.",
            aplicacao:
                "Cremes, produtos farmacêuticos, cosméticos e para envase de produtos de limpeza em geral.",
            tecnico: "",
          },
        },
      ],
      produtosFiltrados: null,
      filtros: null,
    };
  },
  created() {
    this.filtrarProdutos();
  },
  methods: {
    filtrarProdutos() {
      this.filtros = this.$route.params;
      if (this.filtros.campo) {
        this.produtosFiltrados = this.produtos.filter((item) => {
          return item[this.filtros.campo] == this.filtros.valor;
        });
      } else {
        this.produtosFiltrados = this.produtos.filter((item) => !item.repetido);
      }
    },
  },
  watch: {
    $route() {
      this.filtrarProdutos();
    },
  },
  components: {
    Orcamento,
  },
  metaInfo: {
    title: "Produtos",
  },
};
</script>

<style scoped>
.zoom {
  transition: transform .3s;
}
.zoom:hover {
  transform: scale(1.05);
}
</style>